@import "../../bootstrap-theme.scss";

.Xchange-Card {
  border-radius: $btn-border-radius;

  &__Logo {
    display: inline-block;
    min-width: 64px;
    width: 64px;
    height: 64px;
    background-color: $secondary;
    border-radius: $border-radius;
    object-fit: contain;

    &[src] {
      background-color: $white;
    }
  }

  &__Owner {
    font-size: 11px;
    color: $xc-typo-secondary;
    margin-bottom: 0.25rem;
  }

  &__Title {
    font-weight: 800;
    font-size: 15px;
  }

  &__Sub-title {
    font-weight: 700;
    font-size: 12px;
    color: $xc-typo-secondary;
  }

  &__Badge {
    color: $xc-state-info-dark !important;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--client {
      background-color: $xc-brand-primary-default !important;
      color: $light !important;
      border: None !important;
    }
  }

  &__Timeline {
    &__Dates {
      &__Start {
        flex: 1;
      }

      &__Seperator {
        flex: 0;
        font-size: 10px;
        color: $xc-typo-secondary;
      }

      &__End {
        flex: 1;
        text-align: right;
      }
    }
  }

  &__Recuring {
    &__Box {
      font-weight: 600;
      font-size: 12px;
      background-color: $xc-brand-secondary-default;
      border-radius: $border-radius;
      line-height: 24px;
      text-align: center;
      color: $xc-typo-reverse-primary;
    }
  }

  &__Partners {
    display: flex;
    flex-wrap: wrap;
  }

  &__Products-Terms {
    display: flex;
    flex-wrap: wrap;
  }

  small {
    font-weight: 500;
    font-size: 11px;
    color: $xc-typo-disabled;
    margin-top: map-get($spacers, 1);
  }

  &:hover {
    background-color: $xc-material-surface-primary-alternative;
    cursor: pointer;
  }
}
